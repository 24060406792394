<template>
  <div>
    <!-- picF17883DD9BCFC74B056D9C16B13D8623.jpg -->
    <!-- dictionary: uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <img src="@/assets/news-network/happy-holidays.jpg" class="rounded">
    <p>
      <b>"Happy holidays" to you and yours from the entire LEGO Universe team!</b>
    </p>
    <p>
      It's an exciting season of fun with family and friends!
      <router-link to="/creation-lab/user/Ferus123">Ferus123</router-link> posted a message board topic asking if the game should include holidays, and fans have replied "YES"!
    </p>
    <p>
      How will <i>you</i> celebrate the holidays in LEGO Universe?
    </p>
  </div>
</template>
